'use client';
import { useMemo } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import useSWRInfinite from 'swr/infinite';
import { useSWRList } from '@hooks/use-swr-list';
import { eachDayOfInterval, format, parseISO, startOfDay } from 'date-fns';
import { BaseService, useAuthenticatedSWR, useService } from '../api';
import { useSessionCustomer } from './../customer';
import { ORDERS_ENDPOINT, getOrderHistoryPath } from './constants';

class OrderService extends BaseService {
  get = orderId => this.client.get(`${ORDERS_ENDPOINT}${orderId}/`);
  list = () => this.client.get(ORDERS_ENDPOINT);
}

export const useOrders = () => {
  const { data, error } = useAuthenticatedSWR(ORDERS_ENDPOINT);
  return {
    orders: data?.data,
    error,
    isLoading: !data && !error,
  };
};

export const useOrder = orderId => {
  const { data, error } = useAuthenticatedSWR(
    orderId ? `${ORDERS_ENDPOINT}${orderId}/` : null,
  );

  return {
    order: data,
    isLoading: orderId ? !error && !data : false,
    error,
  };
};

export const useOrderList = orderIds => {
  const service = useService(OrderService);
  const { mutate: globalMutate } = useSWRConfig();
  const { data, error } = useSWRList(
    orderIds?.map(id => `${ORDERS_ENDPOINT}${id}/`),
  );

  const listOrders = async () => {
    const response = await service.list();
    const ordersIds = response.data.data.map(order => order.id);
    const ordersResponses = await Promise.all(
      ordersIds.map(id => service.get(id)),
    );
    const orders = ordersResponses.map(response => response.data);
    globalMutate(ORDERS_ENDPOINT, response.data, false);

    for (const order of orders) {
      if (order.id) {
        globalMutate(`${ORDERS_ENDPOINT}${order.id}/`, order, false);
      }
    }

    return orders;
  };

  return {
    orders: data,
    isLoading: !error && !data,
    listOrders,
    error,
  };
};

export const useOrderHistory = (limit = 5) => {
  const { customer, isLoading: isCustomerLoading } = useSessionCustomer();
  const getKey = (pageIndex, previousPageData) => {
    pageIndex = pageIndex + 1;
    if (
      isCustomerLoading ||
      (previousPageData && !previousPageData?.data.length)
    )
      return null; // reached the end
    return getOrderHistoryPath(customer?.id, pageIndex, limit); // SWR key
  };
  const {
    data,
    size,
    setSize,
    isLoading: isOrdersLoading,
    isValidating,
    error,
  } = useSWRInfinite(getKey);
  const orders = data?.flatMap(resp => resp.data);
  const nextPage = data?.length ? data[data.length - 1].next_page : null;
  const orderCount = data?.length ? data[0].count : 0;
  return {
    orderCount,
    nextPage,
    orders,
    size,
    setSize,
    isLoading: isOrdersLoading || isCustomerLoading,
    isValidating,
    error,
  };
};

const getMinDate = deliveryDates => deliveryDates?.[0];
const getMaxDate = deliveryDates => deliveryDates?.[deliveryDates.length - 1];
export const getDisabledDates = (deliveryDates, minDate, maxDate) =>
  deliveryDates && minDate && maxDate
    ? eachDayOfInterval({
        start: minDate,
        end: maxDate,
      })
        .filter(item => !deliveryDates.includes(format(item, 'yyyy-MM-dd')))
        .map(item => startOfDay(item))
    : [];

export const useDeliveryDatesByZip = ({
  customerId,
  zipCode,
  transitOption = undefined,
  startDate = undefined,
  commercial = undefined,
}) => {
  const { data, error, mutate } = useSWR(
    customerId && zipCode
      ? `${ORDERS_ENDPOINT}delivery_dates/?customer=${customerId}&zip_code=${zipCode}${
          transitOption ? `&transit_option=${transitOption}` : ''
        }${startDate ? `&start_date=${startDate}` : ''}${
          commercial !== undefined ? `&commercial=${commercial}` : ''
        }`
      : null,
  );
  const deliveryDates = data?.available_dates?.map(date => date.delivery_date);
  const minDate = getMinDate(deliveryDates);
  const maxDate = getMaxDate(deliveryDates);
  const minDateValue = useMemo(
    () => (minDate ? parseISO(minDate) : null),
    [minDate],
  );
  const maxDateValue = useMemo(
    () => (maxDate ? parseISO(maxDate) : null),
    [maxDate],
  );
  const disabledDates = useMemo(
    () => getDisabledDates(deliveryDates, minDateValue, maxDateValue),
    [maxDateValue, minDateValue, deliveryDates],
  );
  return {
    isLoading: !error && !data,
    isError: error,
    deliveryDates,
    disabledDates,
    minDate: minDateValue,
    maxDate: maxDateValue,
    updateDeliveryDates: mutate,
  };
};

export const useFeedingGuidelines = orderId => {
  const { data, error } = useAuthenticatedSWR(
    orderId ? `${ORDERS_ENDPOINT}${orderId}/feeding_guidelines/` : null,
  );

  const getLatestFeedingGuidelines = response => {
    const latestOrderId = response?.data?.meta?.latest_order_id;
    const noFeedingGuidelines = response?.status === 422 && latestOrderId;
    /* eslint-disable react-hooks/rules-of-hooks */
    const { data: latestFeedingGuidelines, error } = useAuthenticatedSWR(
      noFeedingGuidelines
        ? `${ORDERS_ENDPOINT}${latestOrderId}/feeding_guidelines/`
        : null,
    );
    /* eslint-enable react-hooks/rules-of-hooks */

    const isLatestFeedingGuidelinesLoading = !latestFeedingGuidelines && !error;
    return {
      isLoading: noFeedingGuidelines ? isLatestFeedingGuidelinesLoading : false,
      data: latestFeedingGuidelines,
      error,
    };
  };

  return {
    isLoading: orderId ? !data && !error : false,
    data,
    error,
    getLatestFeedingGuidelines,
  };
};
